<template>
	<v-toolbar :rounded="!mobileLayout" :elevation="cardElevation" :class="mobileLayout ? '' : 'ma-2'" dark short>
		<slot name="icon"></slot>
		<v-app-bar-nav-icon v-if="mobileLayout && !$route.meta.hideNav" @click="toggleDrawer"></v-app-bar-nav-icon>
		<v-toolbar-title>{{ name }}</v-toolbar-title>
		<v-spacer></v-spacer>
		<slot name="actions"></slot>
		<template v-if="tabs.length > 0" v-slot:extension>
			<v-tabs show-arrows>
				<template v-for="(tab, index) in tabs">
					<v-tab :key="index" :to="tab.link">
						{{ tab.title }}
					</v-tab>
				</template>
			</v-tabs>
		</template>
		<v-progress-linear :active="loading" absolute bottom color="success" indeterminate></v-progress-linear>
	</v-toolbar>
</template>

<script>
import {EventBus} from '@/event-bus'
import {mapGetters} from 'vuex'

export default {
	name: 'PageBar',
	props: {
		name: {type: String, required: true},
		tabs: {type: Array, default: () => []},
	},
	methods: {
		toggleDrawer() {
			EventBus.$emit('toggle-nav')
		}
	},
	computed: {
		...mapGetters('global/process', [
			'loading',
		]),
	}
}
</script>

<style scoped>

</style>
